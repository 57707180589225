import React from 'react';
import Post from "./Post";
import './App.css';


function App() {
    return (
        <div className="App">
            <Post/>
        </div>
    );
}

export default App;
